import request from './request.js';

// 新增留资
export function insertUserInfo(params) {
    return request.post('/api/users/insertUser', params)
}

// 获取小程序跳转链接
export function getJumpUrl(params) {
    return request.get('https://api.aiautopilot.cn/api/wechat/getScheme', {params})
}

// 获取京东视频数据
export function getJDVideoList(params) {
    return request.get('https://api.aiautopilot.cn/api/jd/getCensorVideo', {params})
}

// 修改京东视频数据
export function handleCensorVideo(params) {
    return request.post('https://api.aiautopilot.cn/api/jd/handleCensorVideo', params)
}

// 获取审查视频素材
export function getCensorVideoMaterial(params) {
    return request.get('https://api.aiautopilot.cn/api/imgVideo/getCensorVideoMaterial', {params})
}

// 处理视频素材
export function handleCensorVideoMaterial(params) {
    return request.post('https://api.aiautopilot.cn/api/imgVideo/handleCensorVideoMaterial', params)
}

// 获取生成视频任务
export function getCompositeVideoTask(params) {
    return request.get('https://api.aiautopilot.cn/api/imgVideo/getCompositeVideoTask', {params})
}

// 上传视频并完成任务
export function completeVideoTasks(params) {
    return request.post('https://api.aiautopilot.cn/api/imgVideo/completeVideoTasks', params)
}
