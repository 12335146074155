import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "../postcss.config"
import * as api from './request/api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant, { Toast } from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.prototype.$toast = Toast
Vue.prototype.$api = api //团队管理的api

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
