import Vue from "vue";
import VueRouter from "vue-router";

//引入路由组件
Vue.use(VueRouter);

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


const router = new VueRouter({
  mode: 'history', // 使用history模式
  routes: [
    {
      path: '/login',
      redirect: '/'
    },
    {
      path: "/",
      name: "Index",
      component: () => import("@/view/home/index.vue"),
      meta: {
        requiresAuth: false,
        title: '迅鹰AI'
      },
    },
    {
      path: "/billiards",
      name: "billiards",
      component: () => import("@/view/billiards/index.vue"),
      meta: {
        requiresAuth: false,
        title: '迅鹰AI'
      },
    },
    {
      path: "/minijump",
      name: "minijump",
      component: () => import("@/view/minijump/index.vue"),
      meta: {
        requiresAuth: false,
        title: '添加客服'
      },
    },
    {
      path: "/videoVerify",
      name: "videoVerify",
      component: () => import("@/view/videoVerify/index.vue"),
      meta: {
        requiresAuth: false,
        title: '视频审核'
      },
    },
    {
      path: "/videoVerify/preVerify",
      name: "preVerify",
      component: () => import("@/view/videoVerify/preVerify.vue"),
      meta: {
        requiresAuth: false,
        title: '视频预审核'
      },
    },
    {
      path: "/videoVerify/censorMaterial",
      name: "censorMaterial",
      component: () => import("@/view/videoVerify/censorMaterial.vue"),
      meta: {
        requiresAuth: false,
        title: '视频素材审核'
      },
    },
    {
      path: "/uploadVideo",
      name: "uploadVideo",
      component: () => import("@/view/uploadVideo/index.vue"),
      meta: {
        requiresAuth: false,
        title: '视频上传'
      },
    },
    // {
    //   path: "/home-main",
    //   name: "homeMain",
    //   meta: {
    //     requiresAuth: true,
    //   },
    //   component: () => import("@/view/home/homeMain.vue"),
    //   children: [
    //     {
    //       path: "/",
    //       name: "home",
    //       meta: {
    //         requiresAuth: true,
    //       },
    //       component: () => import("@/view/home/home.vue"),
    //     }
    //   ],
    // },
  ],
});

// 路由发生变化修改页面 title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router;
